import * as React from "react";
import { Heading, Paragraph } from "grommet";
import { ContentBlock } from "../../../components/content-block";

import DataStrategyImage from "../../../images/pages/consulting/data-strategy.png";
import DataComplianceImage from "../../../images/pages/consulting/data-compliance.png";
import DataGovernanceImage from "../../../images/pages/consulting/data-governance.png";
import OutsourcingImage from "../../../images/pages/consulting/outsourcing.png";

const ConsultingPage = () => {
  return (
    <>
      <Heading level={2} style={{ color: "#7D4CDB" }}>Consulting</Heading>
      <Heading level={3} style={{ color: "#7D4CDB" }}>Data Strategy</Heading>
      <ContentBlock image={DataStrategyImage}>
        <Paragraph fill>
          Perseguindo a missão de apoiar nossos clientes e parceiros em sua
          jornada “data-driven” desenvolvemos uma metodologia que possibilita a
          democratização do uso dos dados por toda a organização com governança
          e a um nível estratégico. É um fator crítico de sucesso neste processo
          de transformação a capacidade de definir uma visão de futuro correta
          em consonância com os reais desafios de negócio atuais e esperados.
          Para se ter essa visão é necessário a avaliação do uso de dados pela
          organização nas dimensões de: Estratégia, Arquitetura, Informação,
          Organização, Dados, Processos e Pessoas, com isso garantimos um plano
          de ação efetivo e resultados perceptíveis.
        </Paragraph>
      </ContentBlock>
      <Paragraph fill>
        Nossa metodologia permite que a abrangência e profundidade do trabalho a
        ser realizado leve em consideração a necessidade e ambição do cliente,
        permitindo assim que a transformação ocorra em áreas e/ou assuntos
        específicos ou em toda organização.
      </Paragraph>

      <Heading level={3} style={{ color: "#7D4CDB" }}>Data Governance</Heading>
      <ContentBlock image={DataGovernanceImage} reverse>
        <Paragraph fill>
          Com a transformação digital e a crescente competitividade do mercado,
          passou a ser fundamental evoluir as práticas de governança e gestão
          dos dados. Estabelecer um alto nível de confiança nessa gestão é um
          pré-requisito para atingir o objetivo de se tornar “Data-Driven“.
        </Paragraph>
      </ContentBlock>
      <Paragraph fill>
        Governança de Dados consiste em uma orquestração formal de pessoas,
        processos e tecnologia, portanto sua implementação vai além das questões
        técnicas ou tecnológicas, é necessário também mudanças em aspectos não
        tecnológicos, ter um forte envolvimento e estreito alinhamento com a
        comunidade de usuários finais e a capacidade de definir uma visão de
        futuro correta em consonância com os reais desafios de negócios atuais e
        futuros são fatores críticos de sucesso e fundamentais para garantir
        planos de ação efetivos e resultados perceptíveis. Através de
        metodologia alinhada às melhores práticas de mercado, ajudamos a sua
        empresa a estabelecer uma estratégia de governança personalizada e
        sólida, e um processo de qualidade recorrente, aumentando assim a
        agilidade e eficiência dos seus dados.
      </Paragraph>

      <Heading level={3} style={{ color: "#7D4CDB" }}>Data Compliance</Heading>
      <ContentBlock image={DataComplianceImage}>
        <Paragraph fill>
          Com a transformação digital e a crescente competitividade do mercado,
          passou a ser fundamental evoluir as práticas de governança e gestão
          dos dados. Estabelecer um alto nível de confiança nessa gestão é um
          pré-requisito para atingir o objetivo de se tornar "data-driven“.
        </Paragraph>
      </ContentBlock>
      <Paragraph fill>
        Através de metodologia alinhada às melhores práticas de mercado,
        ajudamos a sua empresa a estabelecer uma estratégia de governança
        personalizada e sólida, e um processo de qualidade recorrente,
        aumentando assim a agilidade e eficiência dos seus dados.
      </Paragraph>
      <Paragraph fill>
        Recomendamos o Assessment para quem quer iniciar um programa de
        governança e gestão de dados ou avaliar o programa atual, visando
        aumentar sua maturidade. Através do assessment conseguimos obter uma
        fotografia da situação atual, e a identificar a visão de onde se quer
        chegar, para a partir daí se estabelecer um plano estratégico para que
        as coisas aconteçam de forma realista, em um contexto específico, com o
        melhor tempo e custo benefício.
      </Paragraph>

      <Heading level={3} style={{ color: "#7D4CDB" }}>Outsourcing</Heading>
      <ContentBlock image={OutsourcingImage} reverse>
        <Paragraph fill>
          Oferecemos complementação de equipe aos nossos clientes, com
          profissionais capacitados para atender suas demandas específicas.
          Realizamos avaliações estruturadas de desempenho, monitorando e
          acompanhando continuamente a performance dos profissionais, garantindo
          assim que ele performe e entregue os resultados esperados.
        </Paragraph>
      </ContentBlock>
    </>
  );
};

export default ConsultingPage;
